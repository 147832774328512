/* eslint-disable camelcase */
import { routes } from '../routes'
import { paramsToQueryString } from '../utils'
import { getAccessToken, getBackendUrl } from './fetchApi'

const baseUrl = getBackendUrl()

export interface SamlSignEcrfOptions {
  endpoint: string
  studyId: string
  inclusionId: string
  sectionsToSign: string[]
  sectionsToUnsign: string[]
}

export const samlSignEcrf = ({
  studyId,
  inclusionId,
  endpoint,
  sectionsToSign,
  sectionsToUnsign
}: SamlSignEcrfOptions) => {
  const params = {
    token: getAccessToken(),
    study_uuid: studyId,
    inclusion_id: inclusionId,
    sections_to_sign: sectionsToSign,
    sections_to_unsign: sectionsToUnsign,
    redirect: window.location.href
  }

  window.location.href = `${baseUrl}${endpoint}&${paramsToQueryString(params)}`
}

export interface SamlExcludeOptions {
  endpoint: string
  studyId: string
  inclusionId: string
  reason: string
}

export const samlExclude = ({ studyId, inclusionId, endpoint, reason }: SamlExcludeOptions) => {
  const params = {
    token: getAccessToken(),
    study_uuid: studyId,
    inclusion_id: inclusionId,
    reason,
    redirect: window.location.href
  }

  window.location.href = `${baseUrl}${endpoint}&${paramsToQueryString(params)}`
}

export interface SamlCountersignEconsentOptions {
  endpoint: string
  studyId: string
  recordId: string
}

export const samlCountersignEconsent = ({ studyId, recordId, endpoint }: SamlCountersignEconsentOptions) => {
  const appUrl = window.location.href.split('/studies/')[0]
  const params = {
    token: getAccessToken(),
    study_uuid: studyId,
    record_id: String(recordId),
    declaration: true,
    redirect: `${appUrl}${routes.studyEconsentSigning(studyId)}`
  }
  window.location.href = `${baseUrl}${endpoint}&${paramsToQueryString(params)}`
}

export interface SamlImportEconsentOptions {
  endpoint: string
  studyId: string
  recordId: string
  fileId: string
}

export const samlImportEconsent = ({ studyId, recordId, endpoint, fileId }: SamlImportEconsentOptions) => {
  const appUrl = window.location.href.split('/studies/')[0]
  const params = {
    token: getAccessToken(),
    study_uuid: studyId,
    record_id: String(recordId),
    file_uuid: fileId,
    redirect: `${appUrl}${routes.studyEconsentList(studyId)}`
  }
  window.location.href = `${baseUrl}${endpoint}&${paramsToQueryString(params)}`
}
